import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { nameMap, nameToTeam, teamMap } from './NameMap';
import { ExpandedTeam } from './ExpandedTeam';
import { eliminatedTeams } from './EliminatedTeams';


export function Leaderboard() {

    const teamNamesAndPlayers = React.useMemo(() => [
      {
        teamName: "Kyle1",
        players: [
          "Connor McDavid", "Leon Draisaitl", "Evan Bouchard", "Roope Hintz", "Jason Robertson", 
          "Joe Pavelski", "Adam Fox", "Artemi Panarin", "Nikita Kucherov", "Nathan MacKinnon",
        ],
      },
      {
        teamName: "Kyle2",
        players: [
          "Connor McDavid", "Leon Draisaitl", "Jason Robertson", "Artemi Panarin", "Adam Fox",
          "Mika Zibanejad", "Vincent Trocheck", "Nikita Kucherov", "Brayden Point", "Nathan MacKinnon",
        ],
      },
      {
        teamName: "Mike1",
        players: [
          "Auston Matthews", "William Nylander", "Mitch Marner", "John Tavares", "Tyler Bertuzzi",
          "Elias Pettersson", "Elias Lindholm", "Quinn Hughes", "Nils Hoglander", "Conor Garland",
        ],
      },
      {
        teamName: "Mike2",
        players: [
          "Artemi Panarin", "Nikita Kucherov", "Brayden Point", "Nathan MacKinnon", "Cale Makar",
          "David Pastrnak", "Brad Marchand", "Sebastian Aho", "Andrei Svechnikov", "Jake Guentzel",
        ],
      },
      {
        teamName: "Brenda1",
        players: [
          "Connor McDavid", "Leon Draisaitl", "David Pastrnak", "Nathan MacKinnon", "Nikita Kucherov",
          "Jason Robertson", "Jake Guentzel", "Sebastian Aho", "Miro Heiskanen", "Artemi Panarin",
        ],
      },
      {
        teamName: "Brenda2",
        players: [
          "Connor McDavid", "Leon Draisaitl", "Auston Matthews", "Nathan MacKinnon", "Mitch Marner",
          "Jason Robertson", "Sebastian Aho", "Artemi Panarin", "John Tavares", "Nikita Kucherov",
        ],
      },
      {
        teamName: "Chantal",
        players: [
          "Auston Matthews", "Mitch Marner", "Nathan MacKinnon", "J.T. Miller", "Quinn Hughes",
          "David Pastrnak", "Connor McDavid", "Leon Draisaitl", "Sam Reinhart", "Matthew Tkachuk",
        ],
      },
      {
        teamName: "Kevin",
        players: [
          "Jason Robertson", "Joe Pavelski", "Auston Matthews", "Tyler Bertuzzi", "J.T. Miller",
          "Quinn Hughes", "Sebastian Aho", "Jake Guentzel", "Artemi Panarin", "Chris Kreider",
        ],
      },
      {
        teamName: "Kevin/Chantal",
        players: [
          "J.T. Miller", "Quinn Hughes", "Nikita Kucherov", "Mark Scheifele", "Jason Robertson",
          "Joe Pavelski", "Artemi Panarin", "Vincent Trocheck", "Sebastian Aho", "Jake Guentzel",
        ],
      },
    ], []);
    const [ teams, setTeams ] = React.useState([]);
    const [ expandedTeam, setExpandedTeam ] = React.useState(-1);

    React.useEffect(() => {
      async function getPlayerPoints(playerId, season='20212022') {
        const getPlayerPointsUrl = 'https://tibbf9lujl.execute-api.us-east-1.amazonaws.com/player-points';
        const json_body = await (await fetch(`https://tibbf9lujl.execute-api.us-east-1.amazonaws.com/player-points?player_ids=${playerId}&season=${season}`)).json();
        const res = json_body[playerId];
        return res;
      }


      async function getTeam(teamNameAndPlayers, playerPoints) {
        const team = {
          teamName: teamNameAndPlayers.teamName,
          totalPoints: 0,
          pointsToday: 0,
        };
        
        const players = [];
        const playerIds = Object.keys(nameMap).map((key) => nameMap[key]);
        for (var i=0; i < teamNameAndPlayers.players.length; i++) {
          const playerName = teamNameAndPlayers.players[i];
          const playerId = nameMap[playerName];
          const points = playerPoints[playerIds.findIndex((x) => x == playerId)];
          players.push({
            playerName: playerName,
            playerPoints: points[0],
            playerPointsToday: points[1],
            team: nameToTeam[playerName],
            eliminated: eliminatedTeams[nameToTeam[playerName]],
          });
          team.totalPoints += points[0];
          team.pointsToday += points[1]
        }
        players.sort((x,y) => {
          return x.eliminated - y.eliminated || -x.playerPoints + y.playerPoints || x.team > y.team || x.playerName > y.playerName;
        })
        team.players = players;
        team.playersRemaining = team.players.reduce((x,y) => x+(!y.eliminated ? 1 : 0), 0);   
        return team;
      }
      const playerIds = Object.keys(nameMap).map((key) => nameMap[key]);
      Promise.all(playerIds.map((playerId) => getPlayerPoints(playerId, '20232024'))).then((playerPoints) => 
        Promise.all(teamNamesAndPlayers.map((teamNameAndPlayers) => getTeam(teamNameAndPlayers, playerPoints))).then((newTeams) => {
          newTeams.sort((x,y) => { return -x.totalPoints + y.totalPoints || x.teamName > y.teamName });
          return newTeams;
        }).then((newTeams) => setTeams(newTeams))
      );
      
    }, [setTeams, teamNamesAndPlayers]);

    return (teams.length === 0 ? <h3>Loading...</h3> : ( expandedTeam === -1 ?
        (<div>
          <div style={{zIndex: 2, position: "relative"}}>
          <h1>Welcome to the Playoff Pool!</h1>
          <h2>Standings</h2>
          <h4>(Click team to show players)</h4>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: 115 }}>Team</TableCell>
                <TableCell sx={{ width: 10 }} align="right">Points</TableCell>
                <TableCell sx={{ width: 10 }} align="right">Points Today</TableCell>
                <TableCell sx={{ width: 10 }} align="right">Players Remaining</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teams.map((row, index) => (
                <TableRow
                  key={row.teamName}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <button style={{background:'none', border:'none'}} onClick={() => {setExpandedTeam(index)}}>{index+1}. {row.teamName}</button>
                  </TableCell>
                  <TableCell align="right">{row.totalPoints}</TableCell>
                  <TableCell align="right">{row.pointsToday}</TableCell>
                  <TableCell align="right">{row.playersRemaining}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </div>
        </div>):
        (<div>
          <button onClick={() => {setExpandedTeam(-1)}}>Back to Standings</button>
          <ExpandedTeam team={teams[expandedTeam]} />
        </div>)
    ));
}
