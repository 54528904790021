import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export function ExpandedTeam(props) {
    const getImagePath = (playerName) => {
      const names = playerName.split(' ');
      const lastName = names[names.length - 1].toLowerCase();
      const path = `./${lastName}.png`;
      console.log(path);
      return path;
    }
    const images = require.context('./img', true);

    const { team } = props;
    return <TableContainer component={Paper}>
          <Table  size="small" aria-label="a dense table">
            <TableHead>
              <TableRow key={'headers'}>
                <TableCell sx={{width: 10}}>Team</TableCell>
                <TableCell sx={{width: 10}} />
                <TableCell sx={{width: 40}}>Player</TableCell>
                <TableCell sx={{width: 10}} align="right">Points</TableCell>
                <TableCell sx={{width: 40}} align="right">Points Today</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {team.players.map((row) => (
                <TableRow
                  key={row.playerName}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                  <div style={{color: (row.eliminated ? 'grey' : 'black')}}>{row.team}</div>
                  </TableCell>
                  <TableCell>
                    <div >
                       <img style={{opacity: 1 - 0.5*row.eliminated}} height={60} src={images(getImagePath(row.playerName))}/>
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <div style={{color: (row.eliminated ? 'grey' : 'black')}}>
                      {row.playerName}
                    </div>
                  </TableCell>
                  <TableCell align="right">
                  <div style={{color: (row.eliminated ? 'grey' : 'black')}}>{row.playerPoints}</div>
                  </TableCell>
                  <TableCell align="right">
                  <div style={{color: (row.eliminated ? 'grey' : 'black')}}>{row.playerPointsToday}</div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> 
} 