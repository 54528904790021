export const nameMap = {
    "Connor McDavid":  8478402,
    "Leon Draisaitl": 8477934,
    "Nathan MacKinnon": 8477492,
    "Cale Makar": 8480069,
    "Nikita Kucherov": 8476453, 
    "Brayden Point": 8478010, 
    "David Pastrnak": 8477956,
    "Brad Marchand": 8473419,
    "Mika Zibanejad": 8476459,
    "Artemi Panarin": 8478550,
    "Evan Bouchard": 8480803,
    "William Nylander": 8477939,
    "Auston Matthews": 8479318,
    "John Tavares": 8475166,
    "Jason Robertson": 8480027,
    "Joe Pavelski": 8470794,
    "Mitch Marner": 8478483,
    "Sebastian Aho": 8478427,
    "Chris Kreider": 8475184,
    "Roope Hintz": 8478449,
    "Miro Heiskanen": 8480036,
    "Adam Fox": 8479323,
    "Vincent Trocheck": 8476389,
    "Tyler Bertuzzi": 8477479,
    "Elias Pettersson": 8480012,
    "Elias Lindholm": 8477496,
    "Quinn Hughes": 8480800,
    "Nils Hoglander": 8481535,
    "Conor Garland": 8478856,
    "Andrei Svechnikov": 8480830,
    "Jake Guentzel": 8477404,
    "J.T. Miller": 8476468,
    "Sam Reinhart": 8477933,
    "Matthew Tkachuk": 8479314,
    "Mark Scheifele": 8476460,
};

export const nameToTeam = {
    "Connor McDavid": "EDM",
    "Leon Draisaitl": "EDM",
    "Nathan MacKinnon": "COL",
    "Mikko Rantanen": "COL",
    "Cale Makar": "COL",
    "Nikita Kucherov": "TBL", 
    "Brayden Point": "TBL", 
    "Steven Stamkos": "TBL",
    "David Pastrnak": "BOS",
    "Brad Marchand": "BOS",
    "Ryan Nugent-Hopkins": "EDM",
    "Zach Hyman": "EDM",
    "Mika Zibanejad": "NYR",
    "Patrice Bergeron": "BOS",
    "Artemi Panarin": "NYR",
    "Evan Bouchard": "EDM",
    "William Nylander": "TOR",
    "Auston Matthews": "TOR",
    "John Tavares": "TOR",
    "Jason Robertson": "DAL",
    "Joe Pavelski": "DAL",
    "Tyler Seguin": "DAL",
    "Mitch Marner": "TOR",
    "Jack Eichel": "VGK",
    "Chandler Stephenson": "VGK",
    "Jonathan Marchessault": "VGK",
    "Sebastian Aho": "CAR",
    "Martin Necas": "CAR",
    "Chris Kreider": "NYR",
    "Roope Hintz": "DAL",
    "Adam Fox": "NYR",
    "Miro Heiskanen": "DAL",
    "Vincent Trocheck": "NYR",
    "Tyler Bertuzzi": "TOR",
    "Elias Pettersson": "VAN", 
    "Elias Lindholm": "VAN", 
    "Quinn Hughes": "VAN", 
    "Nils Hoglander": "VAN", 
    "Conor Garland": "VAN",
    "Andrei Svechnikov": "CAR",
    "Jake Guentzel": "CAR",
    "J.T. Miller": "VAN",
    "Sam Reinhart": "FLA",
    "Matthew Tkachuk": "FLA",
    "Mark Scheifele": "WPG",
};

export const teamMap = {
    "NJD": 1,
    "NYR": 3,
    "BOS": 6,
    "TOR": 10,
    "CAR": 12,
    "TBL": 14,
    "COL": 21,
    "EDM": 22,
    "DAL": 25,
    "VGK": 54,
};