export const eliminatedTeams = {
    "EDM": false,
    "COL": true,
    "DAL": true,
    "TBL": true,
    "TOR": true,
    "NYR": true,
    "BOS": true,
    "CAR": true,
    "VAN": true,
    "FLA": false,
    "WPG": true,
}
